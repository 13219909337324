<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#F1F4FB;"
      d="M366.345,406.069H66.207c-9.751,0-17.655-7.904-17.655-17.655V17.655C48.552,7.904,56.456,0,66.207,0
	h300.138C376.096,0,384,7.904,384,17.655v370.759C384,398.165,376.096,406.069,366.345,406.069z"
    />
    <path
      style="fill:#D5DCED;"
      d="M384,388.414v-76.992c-0.907,0.322-1.869,0.494-2.751,0.882
	c-6.307-6.593-14.733-11.025-24.111-11.964c-1.358-0.136-2.705-0.202-4.034-0.202c-1.486,0-2.958,0.081-4.414,0.241v-92.93
	c0-21.903-17.82-39.723-39.723-39.724l0,0c-1.332,0-2.677,0.066-4.036,0.202c-20.012,2.004-35.689,19.916-35.689,40.781v101.773
	l-21.581,21.581c-15.241,15.241-21.393,36.866-16.456,57.847l3.802,16.16h131.338C376.095,406.069,384,398.164,384,388.414z"
    />
    <circle style="fill:#B4E66E;" cx="128" cy="105.931" r="26.483" />
    <circle style="fill:#DAE169;" cx="128" cy="203.034" r="26.483" />
    <circle style="fill:#FFDC64;" cx="128" cy="300.138" r="26.483" />
    <path
      style="fill:#7F8499;"
      d="M331.034,229.517H189.793c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h141.241
	c4.879,0,8.828,3.953,8.828,8.828S335.914,229.517,331.034,229.517z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M295.724,194.207H189.793c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h105.931
	c4.879,0,8.828,3.953,8.828,8.828C304.552,190.254,300.604,194.207,295.724,194.207z"
    />
    <path
      style="fill:#7F8499;"
      d="M331.034,326.621H189.793c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h141.241
	c4.879,0,8.828,3.953,8.828,8.828S335.914,326.621,331.034,326.621z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M295.724,291.31H189.793c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h105.931
	c4.879,0,8.828,3.953,8.828,8.828S300.604,291.31,295.724,291.31z"
    />
    <path
      style="fill:#7F8499;"
      d="M331.034,132.414H189.793c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h141.241
	c4.879,0,8.828,3.953,8.828,8.828C339.862,128.461,335.914,132.414,331.034,132.414z"
    />
    <path
      style="fill:#5B5D6E;"
      d="M295.724,97.103H189.793c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h105.931
	c4.879,0,8.828,3.953,8.828,8.828C304.552,93.151,300.604,97.103,295.724,97.103z"
    />
    <path
      style="fill:#F0C087;"
      d="M443.656,335.563c-13.21-1.323-24.345,9.015-24.345,21.954v-7.569
	c0-11.544-8.306-22.063-19.794-23.213c-13.209-1.323-24.344,9.015-24.344,21.954v-7.569c0-11.544-8.306-22.063-19.794-23.213
	c-13.209-1.323-24.344,9.015-24.344,21.954V207.448c0-12.939-11.135-23.277-24.345-21.954c-11.486,1.15-19.793,11.669-19.793,23.213
	v109.086l-26.752,26.752c-10.808,10.808-15.255,26.44-11.754,41.32l18.47,78.495c6.567,27.913,31.475,47.64,60.15,47.64h74.645
	c34.127,0,61.793-27.666,61.793-61.793v-91.431C463.448,347.232,455.143,336.713,443.656,335.563z"
    />
    <g>
      <path
        style="fill:#E6AF78;"
        d="M339.862,361.377c4.875,0,8.828-3.953,8.828-8.828v-34.194
		c-10.052,2.061-17.655,10.844-17.655,21.506v12.687C331.034,357.425,334.987,361.377,339.862,361.377z"
      />
      <path
        style="fill:#E6AF78;"
        d="M384,370.205c4.875,0,8.828-3.953,8.828-8.828v-34.194c-10.052,2.061-17.655,10.844-17.655,21.506
		v12.687C375.172,366.252,379.125,370.205,384,370.205z"
      />
      <path
        style="fill:#E6AF78;"
        d="M428.138,379.032c4.875,0,8.828-3.953,8.828-8.828v-34.194
		c-10.052,2.061-17.655,10.844-17.655,21.506v12.687C419.31,375.08,423.263,379.032,428.138,379.032z"
      />
      <path
        style="fill:#E6AF78;"
        d="M288.885,464.36l-20.467-86.985c-2.259-9.601,0.61-19.688,7.585-26.663l10.893-10.894v22.113
		c0,4.875,3.953,8.828,8.828,8.828c4.875,0,8.828-3.953,8.828-8.828V185.933c-10.344,2.173-17.655,11.972-17.655,22.773v109.087
		l-26.752,26.752c-10.808,10.808-15.255,26.44-11.754,41.32l18.47,78.495c6.567,27.913,31.475,47.64,60.15,47.64h22.026
		C320.36,512,295.453,492.273,288.885,464.36z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
